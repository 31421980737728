import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import DualColumns from "../components/dual-columns/dual-columns"
import ImageLink from "../components/image-link/image-link"
import Video from "../components/video/video"

const IndexPage = () => {
  const buttonImgs = useStaticQuery(graphql`
    query {
      about: file(relativePath: { eq: "about.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contact: file(relativePath: { eq: "contact-us.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      playerRelations: file(relativePath: { eq: "player-relations.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resources: file(relativePath: { eq: "resources.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="USLPA - Home" />
      <Hero title="One Voice, Louder Than Ever" />
      <div className="white-content">
        <DualColumns>
          <div className="col">
            <h3>Who are We</h3>
            <p>
              The USL Players Association was founded in January of 2018. In
              November of 2019, the United Soccer League formally recognized the
              USLPA as the collective bargaining agent for all USL Championship
              players, as authorized by USL Championship Players. In February of
              2020, the United Soccer League formally recognized the USLPA as
              the collective bargaining agent for all USL League One players, as
              authorized by USL League One players. The USLPA represents all
              players in either league who are party to a USL standard player
              contract. The USLPA does not represent any players party to an
              academy or loan contract.
            </p>
          </div>
          <div className="col">
            <h3>Our Purpose</h3>
            <p>
              The goals of the USLPA are to increase the popularity of soccer in
              North America, strengthen the stature of teth USL, advance the
              interests of its players, improve the terms and conditions of USL
              player employment, and bargain collectively on behalf of all USL
              Players with the USL
            </p>
          </div>
        </DualColumns>
      </div>
      <Video url="https://www.youtube.com/embed/3enyTVQisKY" />
      <div className="white-content">
        <h2 className="chevron">Resources</h2>
        <div className="container">
          <div className="resources-wrapper">
            <ImageLink
              url={"resources"}
              text={"USL Championship CBA Resources"}
              img={buttonImgs.resources.childImageSharp.fluid}
            />
            <ImageLink
              url={"about"}
              text={"About Us"}
              img={buttonImgs.about.childImageSharp.fluid}
            />
            <ImageLink
              url={"player-relations"}
              text={"Player Relations"}
              img={buttonImgs.playerRelations.childImageSharp.fluid}
            />
            <ImageLink
              url={"contact-us"}
              text={"General Contact"}
              img={buttonImgs.contact.childImageSharp.fluid}
            />
            <ImageLink
              url={"https://onrise.care/get-care-now"}
              text={"Onrise Mental Health Solutions"}
              img={buttonImgs.contact.childImageSharp.fluid}
              ext
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
