import React from "react"
import BackgroundImage from "gatsby-background-image"
import "./image-link.scss"
import { Link } from "gatsby"

const ImageLink = props => {
  return (
    <Link to={props.url} className="image-link" target={props.ext ? '_blank' : '_self'}>
    <BackgroundImage
      Tag="div"
      className="image-link--image"
      fluid={props.img}
    >
      {props.text}
    </BackgroundImage>
    </Link>
  )
}

export default ImageLink
