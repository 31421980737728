import React from "react"
import PropTypes from "prop-types"
import "./video.scss"

const Video = ({ url }) => {
  return (
    <div className="container">
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src={url}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  )
}

Video.propTypes = {
  url: PropTypes.node.isRequired,
}

export default Video
